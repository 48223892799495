import {createAction} from "redux-act";
import {Confederation} from "apps/GOALSCORER/modules/types";

export interface ICountry {
	id: number;
	confederation: Confederation | null;
	feedId: string;
	name: string;
	code2: string;
	code3: string;
	commonality: "common" | "rare";
	usersUnlockedPercentage: number;
}

export interface ICountriesResponse {
	countries: ICountry[];
}

export const fetchCountries = createAction();
export const fetchCountriesSuccess = createAction<ICountriesResponse>();
export const fetchCountriesError = createAction<Error["message"]>();
