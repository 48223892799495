import {createAction} from "redux-act";
import {IColumnSortDirection, PlayerPool} from "apps/GOALSCORER/modules/types";
import {FilterStats} from "apps/GOALSCORER/modules/enums";

export const openPlayerPool = createAction();
export const closePlayerPool = createAction();

export const openFilters = createAction();
export const closeFilters = createAction();

export interface IFiltersPayload {
	search?: string;
	competition?: number[];
	country?: number[];
	stat?: FilterStats;
}

export const setFilters = createAction<IFiltersPayload>();

export const setColumnSort = createAction<IColumnSortDirection>();

export interface IPlayerPoolModal {
	playerPool: PlayerPool;
	requiredUnlockedCountriesPercentage: number;
	isUnlocked: boolean;
	isModalShown: boolean;
}

export interface IPlayerPoolModalResponse {
	modals: IPlayerPoolModal[];
}

export const getPlayerPoolModals = createAction();
export const getPlayerPoolModalsSuccess = createAction<IPlayerPoolModalResponse>();
export const getPlayerPoolModalsError = createAction<Error["message"]>();

export const setPlayerPoolModalsShown = createAction<PlayerPool[]>();
export const setPlayerPoolModalsShownSuccess = createAction<PlayerPool[]>();
export const setPlayerPoolModalsShownError = createAction<Error["message"]>();
