import {createAction} from "redux-act";
import {StatusType} from "modules";
import {ISquad, ITournament} from "apps/GOALSCORER/modules/actions/gameData";
import {Confederation, PlayerPool} from "apps/GOALSCORER/modules/types";

export const openPlayerModal = createAction<number>();
export const closePlayerModal = createAction();

export interface IPlayerModalPayload {
	playerId: number;
}

export interface IPlayerStat {
	playerId: number;
	contestId: number;
	percentageSelected: number;
	goals: number;
	points: number;
	totalGoals: number;
	totalPoints: number;
	avgGoals: number;
	avgPoints: number;
	contestPercentageSelected: number;
	seasonGoals: number;
	tournamentsGoals: number[];
}

export interface IPlayerContest {
	id: number;
	number: number;
	startDate: string;
	endDate: string;
	status: StatusType;
	tournamentIds: number[];
}

export interface IPlayerModalResponse {
	stats: IPlayerStat[];
	contests: IPlayerContest[];
	tournaments: ITournament[];
	squads: ISquad[];
}

export const fetchPlayerModalInfo = createAction<IPlayerModalPayload>();
export const fetchPlayerModalInfoSuccess = createAction<IPlayerModalResponse>();
export const fetchPlayerModalInfoError = createAction<Error["message"]>();

export const openPredictionsSavedModal = createAction();
export const closePredictionsSavedModal = createAction();

export const openWelcomeModal = createAction();
export const closeWelcomeModal = createAction();

export const openComingSoonModal = createAction();
export const closeComingSoonModal = createAction();

export const openConfederationModal = createAction<Confederation>();
export const closeConfederationModal = createAction();

export interface ICountryModal {
	countryIds: number[];
	firstTime?: boolean;
}

export const openCountryModal = createAction<ICountryModal>();
export const closeCountryModal = createAction();

export const openNewPlayersModal = createAction<PlayerPool[]>();
export const closeNewPlayersModal = createAction();
