import {createAction} from "redux-act";
import {PlayerStatsView} from "apps/GOALSCORER/modules/enums";
import {IPlayer, ISquad, ITournament} from "apps/GOALSCORER/modules/actions";

export interface IPredictionsPayload {
	contestId: number;
}

export interface IUserPredictionsPayload {
	contestId: number;
	userId: number;
}

export interface IGamebar {
	contestPoints: number;
	contestRank: number | null;
	overallPoints: number;
	overallRank: number | null;
	countriesUnlockedCount: number;
	countriesUnlockedPercentage: number;
	unlockedPlayerPoolNumber: number;
	gamezoneLevel: number;
	gamezoneAvatar: string;
}

export interface IPrediction {
	playerId: number;
	points: number;
}

export interface IPredictionsState {
	lineup: IPrediction[];
	prevLineup: IPrediction[];
}

export interface IPredictionsResponse {
	prediction: IPredictionsState;
	gameBar: IGamebar;
}

export interface IUserPredictionsState {
	lineup: IPrediction[];
	tournaments: ITournament[];
	squads: ISquad[];
	players: IPlayer[];
}

export interface IUserPredictionsResponse {
	prediction: IUserPredictionsState;
}

export const getPredictions = createAction<IPredictionsPayload>();
export const getPredictionsSuccess = createAction<IPredictionsResponse>();
export const getPredictionsError = createAction<Error["message"]>();

export const getUserPredictions = createAction<IUserPredictionsPayload>();
export const getUserPredictionsSuccess = createAction<IUserPredictionsResponse>();
export const getUserPredictionsError = createAction<Error["message"]>();

export interface ISetPlayer {
	playerId: number;
	// index: number;
}

export const setPlayer = createAction<ISetPlayer>();
export const removePlayer = createAction<ISetPlayer>();

export interface ISavePayload {
	contestId: number;
	lineup: number[];
}

export const savePredictions = createAction<ISavePayload>();
export const savePredictionsSuccess = createAction<IPredictionsResponse>();
export const savePredictionsError = createAction<Error["message"]>();

export const resetPredictions = createAction<number[]>();

export interface IStatsFilterPayload {
	stats: PlayerStatsView;
}

export const setStatsFilter = createAction<IStatsFilterPayload>();
export const toggleStatsFilter = createAction<boolean>();

export const setIsViewedStreakModal = createAction();
export const setIsViewedStreakModalSuccess = createAction();
export const setIsViewedStreakModalError = createAction<Error["message"]>();
