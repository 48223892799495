import {createAction} from "redux-act";
import {Confederation} from "apps/GOALSCORER/modules/types";

export interface IConfederation {
	id: number;
	name: Confederation;
	requiredUnlockedCountriesToOpen: number;
}

export interface IConfederationResponse {
	confederations: IConfederation[];
}

export const fetchConfederations = createAction();
export const fetchConfederationsSuccess = createAction<IConfederationResponse>();
export const fetchConfederationsError = createAction<Error["message"]>();
