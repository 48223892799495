import {createAction} from "redux-act";
import {IError, ISaveShareParameters, IShareParameters} from "modules/types";

interface ILoginModalPayload {
	title?: string;
	text?: string;
	redirectPath?: string;
	data?: Record<string, unknown>;
}

export const openLoginModal = createAction<ILoginModalPayload>();
export const closeLoginModal = createAction();

interface IShareModalPayload {
	title?: string;
	description?: string;
	shareParameters: IShareParameters;
	saveShareParameters?: ISaveShareParameters;
}

export const openShareModal = createAction<IShareModalPayload>();
export const closeShareModal = createAction();

export const openErrorLeagueModal = createAction<IError>();
export const closeErrorLeagueModal = createAction();

export const openGotcLeagueModal = createAction();
export const closeGotcLeagueModal = createAction();

export const openTermsModal = createAction();
export const closeTermsModal = createAction();
