import React from "react";
import {useTranslation} from "react-i18next";
import {PROJECT_NAME, SUB_NAVIGATION_ITEMS, useShowNavigationLink} from "modules";
import {Exist} from "components";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {media} from "assets/css/media";
import {Container} from "@mui/material";

const Wrapper = styled(Container)`
	&.MuiContainer-root {
		display: none;
		height: 42px;
		width: 100%;
		overflow: auto;
		gap: 24px;
		background-color: #045694;

		@media screen and (max-width: ${media.tablet}) {
			display: flex;
		}
	}
`;

const LinkWrapper = styled.div<{isProjectNav?: boolean}>`
	cursor: pointer;

	.active {
		&:after {
			width: 100%;
			background: linear-gradient(90deg, #00b8ff 0%, #0a84ff 100%);
		}
	}

	> a {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 12px;
		position: relative;
		color: #fff;
		font-weight: 500;
		white-space: nowrap;

		&:after {
			content: "";
			transition: width ease-in-out 250ms;
			position: absolute;
			width: 0;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
			height: 3px;
			background: #fff;
		}

		&.active,
		&.active-project,
		&:hover {
			background: linear-gradient(90deg, #00b8ff 0%, #0a84ff 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}
`;

export const MobileSubNavigation: React.FC = () => {
	const {t} = useTranslation();
	const showNavigationLink = useShowNavigationLink();

	return (
		<Wrapper>
			{SUB_NAVIGATION_ITEMS[PROJECT_NAME] &&
				SUB_NAVIGATION_ITEMS[PROJECT_NAME].map((item) => (
					<Exist when={showNavigationLink(item)} key={item.name}>
						<LinkWrapper isProjectNav={true} className={item.customClass}>
							<NavLink to={item.to}>{t(item.name, item.fallbackName ?? "")}</NavLink>
						</LinkWrapper>
					</Exist>
				))}
		</Wrapper>
	);
};
