import {createAction} from "redux-act";
import {Confederation} from "apps/GOALSCORER/modules/types";

export interface IItem {
	countryId: number;
	contestId: number;
	player: {
		id: number;
		firstName: string;
		lastName: string;
		shortName: string;
		goals: number;
		opponentSquadName: string;
	};
	modalShowed: boolean;
}

export interface IUnlockedCountriesResponse {
	items: IItem[];
}

export const getUnlockedCountries = createAction();
export const getUnlockedCountriesSuccess = createAction<IUnlockedCountriesResponse>();
export const getUnlockedCountriesError = createAction<Error["message"]>();

export const setUnlockedCountryModalShown = createAction<number[]>();
export const setUnlockedCountryModalShownSuccess = createAction<number[]>();
export const setUnlockedCountryModalShownError = createAction<Error["message"]>();

export interface IConfederationProgress {
	confederationId: number;
	lockedCountryIds: number[];
	unlockedCountryIds: number[];
	confederationUnlockPercentage: number;
	isConfederationUnlocked: boolean;
}

export interface IConfederationsProgressResponse {
	confederations: IConfederationProgress[];
}

export const getConfederationsProgress = createAction();
export const getConfederationsProgressSuccess = createAction<IConfederationsProgressResponse>();
export const getConfederationsProgressError = createAction<Error["message"]>();

export const setHighlightedConfederation = createAction<Confederation | null>();
export const setActiveConfederation = createAction<Confederation>();
export const setIsPanning = createAction<boolean>();
